.loginContainer {
    max-width: 400px;
    justify-content: space-evenly;
    left:50%;
    top:50%;
    position:absolute;
    transform:translate(-50%, -50%);
}

.loginContainer .cardLogin {
    margin: 5px;
}

.loginContainer .google {
    margin-top: 10px;
}

.register {
    margin-left: 20px;
}
#cardCredit {
    position: relative;
    display: flex;
    flex-direction: row;
    min-width: 0;
    flex-wrap: wrap;
}

.cardCredit .debit-card {
    width: 295px;
    height: 190px;
    padding: 15px;
    border-radius: 5px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.cardCredit .card-2 {
    border-color: black;
    margin-top: 10px;
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom: 5px;
}

.cardCredit .text-white {
    font-size: 25px
}

.cardCredit .m-0 {
    color: black;
}

.cardCredit .creditBody {
    font-size: 11px;
}

.modalButton {
    margin: 10px;
}

#cardText {
    color: white !important;
}

#creditCardBlock:hover {
    -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}

.switchButton {
    margin-left: 11px;
}
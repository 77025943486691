.card {
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    margin: 10px;
    margin-right: 0px;
    cursor: pointer;
    background-color: #FFFFFF;
    border-color: #FFFFFF;
}

#customCard {
    background-color: #FFFFFF;
}

#iconDash {
    width: 50px;
}

#fontColors {
    font-size: 20px;
}

#tittle {
    margin-left: 10px;
}
.toggle-button {
    display: none;
    position: fixed;
    top: 10px;
    left: 10px;
    z-index: 1000;
    background-color: #fff;
    border: none;
    padding: 10px;
    border-radius: 50%;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);
    cursor: pointer;
}

.userName {
    font-size: 11px;
}

@media (max-width: 768px) {
    .toggle-button {
        display: block;
    }
}


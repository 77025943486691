#cardCreditSelected {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    flex-wrap: wrap;
}

.cardCreditSelected .debit-card:hover {
    transition: all 0.3s ease !important; /* Suaviza a transição */
    transform: translateY(-42px) scale(1.05) !important; /* Sobe e aumenta levemente */
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2) !important; /* Adiciona uma sombra suave */
}

.cardCreditSelected .debit-card {
    min-width: 300px;
    min-height: 190px;
    padding: 15px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    width: 300px;
    height: 200px;
    position: absolute;
    border-radius: 15px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.cardCreditSelected .card-2 {
    border-color: black;
    margin-top: 10px;
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom: 5px;
}

.cardCreditSelected .text-white {
    font-size: 25px
}

.cardCreditSelected .m-0 {
    color: black;
}

.cardCreditSelected .creditBody {
    font-size: 11px;
}

.modalButton {
    margin: 10px;
}

#cardText {
    color: white !important;
}

.cardForm {
    margin-left: 15px;
    margin-top: 15px;
}

.cardCreditSelected {
    position: relative;
    margin-bottom: 100px;
}

.card-stack-container {
    position: relative;
    height: 300px; /* Ajuste conforme necessário */
    width: 100%;
    margin-bottom: -115px;
    z-index: 2;
    display: flex;
    flex-direction: row;
}

@media(max-width: 900px) {
    .card-stack-container {
        flex-direction: column;
        margin-bottom: -200px;
        margin-top: 10px;
        margin-left: 20px;
    }

    .card-stack-container .cardCreditSelected {
        display: none !important;
      }
    
      .card-stack-container .cardCreditSelected.selected {
        display: flex !important;
      }

      .debit-card.card-2.mb-4.selected {
        left: 0 !important;
      }

      .CreditCardSelectedTable {
        font-size: 8px;
      }

      #controlled-tab-example {
        font-size: 10px;
      }
}
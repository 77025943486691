.cardWallet {
    padding: 10PX;
    width: 1305px !important;
    height: 600px;
}

.walletGroup{
    display: flex;
}

.cardWallet .allValue {
    margin: auto;
}

.walletTable {
    cursor: default;
    text-align: center; /* Centraliza horizontalmente */
    vertical-align: middle; /* Centraliza verticalmente */
}



@media(max-width: 900px) {
    .walletGroup{
        flex-flow: column-reverse;
    }

    .cardWallet {
        width: 350px !important;
        height: 600px;
    }

    .walletTable {
        font-size: 8px;
    }
}
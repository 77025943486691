.containerDebtpage {
    display: flex;
    flex-flow: column;
    flex-wrap: wrap;
}

.filters {
    display: flex;
    flex-flow: row;
    
}

#search {
    max-height: 40px;
    min-width: 200px;
    max-width: 200px;
    margin: 5px;
}

.tdd {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    min-width: 150px;
}

.td1 {
    max-width: 150px;
    max-height: 30px;
    cursor: default;
    text-align: center; /* Centraliza horizontalmente */
    vertical-align: middle; /* Centraliza verticalmente */
}

.tr {
    cursor: default;
    text-align: center; /* Centraliza horizontalmente */
    vertical-align: middle; /* Centraliza verticalmente */
}

.table {
    font-size: 13px;
}

.cardTable {
    padding: 15px;
}

#PagesTitle {
    font-size: 30px;
    font-weight: 600;
    color: #8F8EAC;
    margin-left: 10px;
}


@media(max-width: 900px) {
    .filters {
        flex-flow: column;
        
    }
}
.containerWallet .walletCards {
    display: flex;
    flex-flow: row wrap;
    margin-left: 5px;
}



#linha {
    width: 100%;
    margin-left: 10px;
    font-size: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

@media (max-width: 768px) {
    #linha {
        margin-left: 10%;
    }

    .toPay {
        display: none;
    }
}
.loginContainer {
    max-width: 400px;
}

.loginContainer .cardLogin {
    max-width: 350px;
    left: 50%;
    top: 50%;
    position: absolute;
    cursor: auto;
    transform: translate(-50%, -50%);
}

.loginContainer .cardLogin .registerButton {
    cursor: pointer;
    text-decoration: underline;
}

.loginContainer .cardLogin .signButton {
    background-color: #0070D2;
    color: white;

}

.register {
    margin-left: 20px;
}

.signText {
    color: #9FA2B4;
    font-size: 11px;

}

.formLogin {
    margin-top: 15px;
    margin-bottom: 15px;
}

@media (max-width: 768px) {
    .loginContainer {
        max-width: 600px;
    }
    .loginContainer .cardLogin {
        max-width: 500px;
        position: relative;
        margin-top: 500px !important;
    }
}